<template>
  <div className="animated fadeIn">
    <i class="icon-menu mr-1"></i> Marketing List<hr />
        <v-server-table 
          id="dataTableMarketingList" 
          ref="reports-marketing-list"
          name="dataTableMarketingList" 
          class="bg-light vue-table-table mb-4" 
          :columns="columns" 
          :url="url" 
          :options="options" 
          theme="bootstrap4" 
          :useVuex="true"
          :preserveState="true"
        >
          <template slot="dateSubmitted" slot-scope="props">
            {{ $moment(props.row.dateSubmitted).format('YYYY-MM-DD') }}
          </template>
          <template slot="totalSpend" slot-scope="props">
            ${{ props.row.totalSpend.toFixed(2) }}
          </template>
          <b-button-group slot="action" slot-scope="props">
            <b-btn size="sm" variant="info" :to="{ name: 'reportsMarketingDetails', params: { rptId: props.row.id } }">View</b-btn>
            <b-btn v-if="currentUserRole === 'ROLE_ADMIN'" size="sm" variant="danger" @click="reportsMarketingDelete(props.row.id)">Delete</b-btn>
          </b-button-group>
        </v-server-table>
  </div>
</template>

<script>
import Vue from 'vue'
import { HTTP } from '../../services/http-common'
import { ServerTable } from 'vue-tables-2-premium'
import { mapGetters, mapActions } from 'vuex'
Vue.use(ServerTable)
import daterangepicker from 'daterangepicker'
require('daterangepicker/daterangepicker.css')
window.$ = require('jquery')
window.JQuery = require('jquery')
window.moment = require('moment')

export default {
  props: {
    userRole: {
      type: String,
      default: null
    }
  },
  data: function () {
    return {
      options: {
        filterByColumn: true,
        dateColumns: ['dateSubmitted'],
        orderBy: { 
          column: 'dateSubmitted',
          ascending:false 
        },
        perPage: 30,
        perPageValues: [30,60,90],
        headings: {
          id: 'Rept ID', 
          'user.fullName': 'User',
          'business.areaName': 'Franchise', 
          dateSubmitted: 'Submitted', 
          reptYr: 'Rept Yr', 
          reptMo: 'Rept Mo', 
          totalSpend: 'Total Spend'
        },
        sortable: ['id', 'business.areaName', 'user.fullName', 'dateSubmitted', 'reptYr', 'reptMo', 'totalSpend'],
        filterable: ['id', 'business.areaName', 'user.fullName', 'dateSubmitted', 'reptYr', 'reptMo'],
        sortIcon: { base:'fa', up:'fa-sort-asc', down:'fa-sort-desc', is:'fa-sort' },
        pagination: {
          chunk: 5,
          edge: false,
          nav: 'scroll'
        },
        requestFunction (data) {
            return HTTP.get(this.url, {
                params: data
            }).catch(function (e) {
                this.dispatch('error', e);
            }.bind(this));
        },
        requestAdapter (data) {
          let query = {}
          for (let key in data.query) {
            switch (key) {
              case 'dateSubmitted': 
                let param = 'dateSubmitted[after]'
                query[param] = data.query.dateSubmitted.start
                param = 'dateSubmitted[before]'
                query[param] = data.query.dateSubmitted.end
                break
              default: 
                query[key] = data.query[key]
            }
          }
          query.page = data.page ? data.page : '1'
          if (data.orderBy) {
            let param = 'order[' + data.orderBy + ']'
            query[param] = data.ascending ? 'asc' : 'desc'
          } else {
            query['order[dateSubmitted]'] = 'desc'
          }
          return query
        },
        responseAdapter ({data}) {
          return {
            data: data['hydra:member'],
            count: data['hydra:totalItems']
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters([
      'participants',
      'business'
    ]),
    columns () {
      let cols = ['id', 'business.areaName', 'user.fullName', 'dateSubmitted', 'reptYr', 'reptMo', 'totalSpend', 'action']
      if (this.currentBusiness.id) {
        cols.splice(1, 1)
      }
      return cols
    },
    url () {
      return this.currentBusiness.id ? '/reports_marketings?business=' + this.currentBusiness.id : '/reports_marketings'
    }
  },
  methods: {
    ...mapActions([
      'getReportsMarketing',
      'deleteReportsMarketing',
      'resetReports'
    ]),
    reportsMarketingDelete (id) {
      this.$bvModal.msgBoxConfirm('Please click OK to permanently delete this report (#' + id + ').')
      .then(conf => {
        if (conf) {
          this.deleteReportsMarketing(id).then(() => {
            this.$refs['reports-marketing-list'].refresh()
          })
        }
      })
    }
  },
  destroyed () {
    this.resetReports()
  }
};
</script>

<style lang="scss">
.VueTables__date-filter {
  border: 1px solid #ccc;
  padding: 6px;
  border-radius: 4px;
  cursor: pointer;
  font-size: .8rem;
  font-weight: normal;
}
[class$="-filter-wrapper"] .form-control {
  max-width: 60px;
}
[class="VueTables__user.fullName-filter-wrapper"] .form-control {
  max-width: 150px;
}
</style>
